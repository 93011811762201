import Vue from "vue";

const state = {
  showNotifications: true,
  hasUnreadFeatureNotifications: false,
  stripeEnabled: null,
  requireCustomerRegistration: null,
  isAcceptingPayments: null,
  onboardingComplete: null,
}

const getters = {
  showNotifications: (state) => {
    return state.showNotifications
  },
  hasUnreadFeatureNotifications: (state) => {
    return state.hasUnreadFeatureNotifications
  },
  stripeEnabled: (state) => {
    return state.stripeEnabled
  },
  requireCustomerRegistration: (state) => {
    return state.requireCustomerRegistration
  },
  isAcceptingPayments: (state) => {
    return state.isAcceptingPayments
  },
  onboardingComplete: (state) => {
    return state.onboardingComplete
  }
}

const mutations = {
  hideNotifications (state) {
    state.showNotifications = false
  },
  setUnreadFeatureNotifications (state, hasUnread) {
    state.hasUnreadFeatureNotifications = hasUnread
  },
  setClientStatus (state, newStatus) {
    Object.assign(state, newStatus)
  }
}

const actions = {
  async updateClientStatus ({ commit, dispatch }) {
    try {
      const res = await Vue.prototype.$http.payment.get('ft-clients/status')
      commit('setClientStatus', res.data)

      dispatch('updateNotificationsStatus')
    } catch (e) {
    }
  },
  async updateNotificationsStatus ({ commit }) {
    try {
      const res = await Vue.prototype.$http.payment.get('ft-clients/status')
      commit('setClientStatus', res.data)

      const notifications = await Vue.prototype.$http.auth.get('feature-notifications/has-any')
      commit('setUnreadFeatureNotifications', notifications.data)
    } catch (e) {
    }
  }
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
