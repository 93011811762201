import {store} from "@/store/store";

export class Role {
  static ADMIN = 'admin'
  static CLIENT_ADMIN = 'foodytek-client-admin'
  static CLIENT_ACCOUNTANT = 'foodytek-client-accountant'
  static CLIENT_FRONT_DESK = 'foodytek-client-front-desk'
  static CLIENT_CUSTOMER_ADMIN = 'client-customer-admin'
}

const isLocalhost = window.location.hostname === 'localhost'

export function requireRoles (roles, clientIdField = null) {
  return (to, from, next) => {
    const roleAllowed = roles.includes(store.getters.role)
        // if client admin, also allow admins if they have a client id
        || (roles.includes(Role.CLIENT_ADMIN) && store.getters.isClientAdmin)
    const clientValid = clientIdField
        ? store.getters.isAdmin || store.getters.clientId === to.params[ clientIdField ]
        : true

    if (!roleAllowed || !clientValid) {
      if (isLocalhost) {
        console.log(`Role not allowed: ${store.getters.role} - required: ${roles} or client not valid: ${store.getters.clientId} - required[${clientIdField}]: ${to.params[ clientIdField ]}`)
      }
      beforeEnterCatchDefault(to, from, next)
      return
    }

    beforeEnter(to, from, next)
  }
}

export function beforeEnterCatchDefault (to, from, next) {
  if (!store.getters.isAuthenticated) shouldBe(next, '/logout', to)
  else if (store.getters.isAdmin) shouldBe(next, '/devices', to)
  else if (store.getters.isClientAdmin) shouldBe(next, '/dashboard', to)
  else if (store.getters.isClientAccountant) shouldBe(next, '/monthly-reports', to)
  else if (store.getters.isClientFrontDesk) shouldBe(next, '/dashboard', to)
  else if (store.getters.isClientCustomer) {
    if (store.getters.ccRegComplete) shouldBe(next, '/client-customers/info', to)
    else shouldBe(next, '/client-customers/complete-registration', to)
  } else {
    throw new Error('Unknown role: ' + store.getters.role)
  }
}

export function beforeEnter (to, from, next) {
  store.getters.isAuthenticated
      ? store.getters.registrationComplete ? next() : shouldBe(next, '/logout', to)
      : shouldBe(next, '/login', to)
}

function shouldBe (next, shouldBe, to) {
  if (to.path === shouldBe) next()
  else next(shouldBe)
}

function shouldNotBe (next, shouldNotBe, to) {
  if (to.path === shouldNotBe) next('/')
  else next()
}
