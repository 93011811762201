<script>
export default {
  name: "CorrispettiviAgenziaEntrate",
  data () {
    return {
      corrispettivi: [],
      itemsPerPage: -1,
      headers: [
        { value: 'toText', text: 'Data invio' },
        { value: 'fromText', text: 'Data invio precedente' },
        { value: 'serialNumber', text: 'Dispositivo' },
        { value: 'bagNumber', text: 'Numero sacchetto' },
        { value: 'purchasesText', text: 'Venduto con App', sortable: false },
        { value: 'refundsText', text: 'Rimborsi App', sortable: false },
        { value: 'legacyText', text: 'Venduto senza App', sortable: false },
        { value: 'cashTopUpsText', text: 'Incassato', sortable: false },
        { value: 'actions', text: 'Azioni', sortable: false },
      ]
    }
  },
  mounted () {
    this.getCorrispettivi()
  },
  methods: {
    async getCorrispettivi () {
      try {
        const r = await this.$http.reports('v2/corrispettivi')

        r.data.forEach(i => {
          i.toText = this.formatDateTime(i.to)
          i.fromText = this.formatDateTime(i.from)

          i.legacyText = this.formatMoney(i.legacy)
          i.purchasesText = this.formatMoney(i.purchases)
          i.refundsText = this.formatMoney(i.refunds)
          i.cashTopUpsText = this.formatMoney(i.cashTopUps)
        })

        this.corrispettivi = r.data
      } catch (e) {
      }
    },
    async downloadXml (item) {
      const xml = await this.$http.reports.get(`v2/corrispettivi/${item.id}/xml`)

      const fromTxt = new Date(item.from * 1000).toISOString()
      const toTxt = new Date(item.to * 1000).toISOString()
      const filename = `Corrispettivo_${item.serialNumber}_${fromTxt}_${toTxt}.xml`

      this.createFileAndDownload(xml.data, 'text/xml', filename)
    }
  }
}
</script>

<template>
  <div>
    <h1 class="mb-3">Corrispettivi inviati all'Agenzia delle Entrate</h1>

    <v-card outlined>
      <v-data-table
          hide-default-footer
          :items="corrispettivi"
          :headers="headers"
          :items-per-page="itemsPerPage"
          item-key="to"
      >
        <template v-slot:item.serialNumber="{ value }">
          <v-chip link outlined :to="`/devices/${value}`">{{ value }}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadXml(item)"
              >
                XML
              </v-btn>
            </template>
            <span>Scarica file XML inviato</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
