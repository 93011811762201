<script>
import {ActionModal} from '@simple-it/shared-vuetify'
import PriceInput from "@/components/fields/PriceInput.vue";

export default {
  name: 'AllowDayEntranceToUserModal',
  components: { ActionModal, PriceInput },
  props: {
    ftClientId: {
      type: String,
      required: true
    },
    user: {
      type: Object
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modal: false,
      entranceFees: [],
      data: {
        requirementId: '',
        customFeeAmount: null
      },
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12,
        // md: 6
      }
    }
  },
  mounted () {
    this.loadRequirements()
  },
  computed: {
    fields () {
      return [
        { value: 'requirementId', label: this.$t('Select entrance fee'), rules: 'required' },
        { 
          value: 'customFeeAmount', 
          label: this.$t('Custom fee amount'), 
          type: 'number',
          rules: 'required|numeric|min:0'
        }
      ]
    },
    show: {
      get () {
        return this.user != null
      },
      set () {
        this.$emit('update:user', null)
        this.$emit('close')
      }
    }
  },
  watch: {
    show (open) {
      if (!open) return

      if (this.entranceFees.length === 1) {
        this.data.requirementId = this.entranceFees[0].id
        this.data.customFeeAmount = this.entranceFees[0].feeAmount
      }
    },
    'data.requirementId'(newId) {
      const selectedFee = this.entranceFees.find(fee => fee.id === newId)
      if (selectedFee) {
        this.data.customFeeAmount = selectedFee.feeAmount
      }
    }
  },
  methods: {
    async create () {
      const userId = this.user.user.id

      return this.$http.payment.post(`requirements/fulfilment/dashboard`, {
        userId,
        requirementId: this.data.requirementId,
        feeAmount: this.data.customFeeAmount
      })
    },
    close () {
      this.$emit('close')
    },
    async loadRequirements () {
      const res = await this.$http.payment.get('requirements?type=ENTRANCE_FEE')
      this.entranceFees = res.data
      if (this.entranceFees.length > 0) {
        this.$emit('update:visible', true)
      }
    }
  }
}
</script>

<template>
  <action-modal
      v-if="show "
      v-model="show"
      :data="data"
      :edit-mode="false"
      :submit-btn-text="$t('Allow entrance')"
      :cancel-btn-text="$t('Cancel')"
      :loading-text="$t('Loading...')"

      :submit-title="$t('Allow {name} a day entrance to driving range', { name: user.name })"
      :submit-success-message="$t('User can now play at the driving range')"

      :fields="fields"
      :field-default-options="fieldDefaultOptions"

      :submit-promise-callback="create"
      @close="close"
  >
    <template #field.requirementId="{ errors, label, classes }">
      <v-select
          :label="label"
          outlined
          :class="classes"
          v-model="data.requirementId"
          item-value="id"
          :items="entranceFees"
          :error-messages="errors"
          class="hide-input"
      >
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
              <span v-if="item.feeAmount">
                -
                <span class="font-weight-bold" v-text="formatMoney(item.feeAmount)" />
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.devices ? item.devices.map(i => i.serialNumber).join(', ') : $t("It doesn't apply to any device") }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <template #selection="{ item }">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
              <span v-if="item.feeAmount">
                -
                <span class="font-weight-bold" v-text="formatMoney(item.feeAmount)" />
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.devices ? item.devices.map(i => i.serialNumber).join(', ') : $t("It doesn't apply to any device") }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>
    </template>

    <template #field.customFeeAmount="{ errors, label, classes }">
      <price-input
          :label="label"
          outlined
          :class="classes"
          :error-messages="errors"
          v-model="data.customFeeAmount"
      />
    </template>
  </action-modal>
</template>

// by adding "scope", the .hide-input stop working
<style>
.hide-input input {
  display: none;
}
</style>
