<template>
  <div>
    <action-dialog
        v-model="showValue"
        :data="dataObj"
        :edit-mode="editMode"
        :submit-btn-text="$t('Add')"
        :edit-btn-text="$t('Edit')"
        :cancel-btn-text="$t('Cancel')"
        :loading-text="$t('Loading...')"

        :submit-title="userEmail ? $t('Add {email} to a group', { email: userEmail }) : $t('Add a new user via email')"
        :submit-success-message="$t('User added successfully!')"
        :edit-title="$t('Change user validity period')"
        :edit-success-message="$t('Validity period changed')"

        :fields="fields"
        :field-default-options="fieldDefaultOptions"

        :submit-promise-callback="create"
        :edit-promise-callback="edit"
        @close="close"
    >
      <template #field.groupId="{ errors, label, classes }">
        <v-autocomplete
            :label="label"
            outlined
            :class="classes"
            v-model="dataObj.groupId"
            :items="groups"
            :error-messages="errors"
        />
      </template>
      <template #field.startDate="{ errors, label, classes }">
        <ft-date-field
            v-if="dataObj"
            :label="label"
            :class="classes"
            v-model="dataObj.startDate"
            outlined clearable
            :message="$t('It can be empty')"
            :error-messages="errors"
        />
      </template>
      <template #field.endDate="{ errors, label, classes }">
        <ft-date-field
            v-if="dataObj"
            :label="label"
            :class="classes"
            v-model="dataObj.endDate"
            :min="minDate"
            outlined clearable
            :message="$t('It can be empty')"
            :error-messages="errors"
        />
      </template>
    </action-dialog>
  </div>
</template>

<script>
import FtDateField from "@/components/fields/FtDateField.vue";
import ActionDialog from "@/components/modals/ActionDialog/ActionDialog.vue";

export default {
  name: 'AddUserToGroupModal',
  components: { ActionDialog, FtDateField },
  props: {
    value: {
      type: Boolean
    },
    dataObj: {
      type: Object,
      default: () => ({}) // defaults to an empty object
    },
    editMode: {
      type: Boolean,
      required: true
    },
    userEmail: String,
    groupId: String,
  },
  data () {
    return {
      today: new Date().toISOString().substring(0, 10),
      groups: null,
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12,
        // md: 6
      }
    }
  },
  computed: {
    userEmailValue () {
      return this.userEmail ?? this.dataObj?.email
    },
    groupIdValue () {
      return this.groupId ?? this.dataObj?.groupId
    },
    minDate () {
      // return the maximum between today and the start date
      return this.dataObj.startDate > this.today ? this.dataObj.startDate : this.today
    },
    fields () {
      return [
        { value: 'email', label: 'Email', rules: 'required', hide: !!this.userEmail || this.editMode },
        { value: 'groupId', label: this.$t('Group'), rules: 'required', hide: !!this.groupId },
        { value: 'startDate', label: this.$t('Beginning of validity') },
        { value: 'endDate', label: this.$t('End of validity') },
      ].filter(i => !i.hide)
    },
    showValue: {
      get () {
        return this.value
      },
      set () {
        this.$emit('input', false)
        this.$emit('close')
      }
    },
  },
  watch: {
    dataObj (val) {
      if (!val || Object.keys(val) === 0) return
      this.loadGroups()

      if (val.startDate) {
        const date = this.getDateObj(val.startDate)
        this.dataObj.startDate = date.toISOString().substring(0, 10)
      }

      if (val.endDate) {
        const date = this.getDateObj(val.endDate)
        this.dataObj.endDate = date.toISOString().substring(0, 10)
      }
    },
    'dataObj.startDate' (val) {
      // if the start date is after the end date, set the end date to the start date
      if (this.dataObj.endDate < val) {
        this.dataObj.endDate = val
      }
    }
  },
  methods: {
    async loadGroups () {
      if (this.groupId) return

      if (this.groups !== null) return
      this.groups = []

      const res = await this.$http.auth.get('/user-groups')
      this.groups = res.data.map(ug => ({ text: ug.name, value: ug.id }))
    },
    create () {
      return this.$http.auth.post(
          `/user-groups/${this.groupIdValue}/users`,
          {
            email: this.userEmailValue
          }, {
            params: {
              start: this.dataObj.startDate,
              end: this.dataObj.endDate
            }
          }
      )
    },
    edit () {
      return this.$http.auth.put(
          `/user-groups/${this.groupIdValue}/users/${this.dataObj.userId}`,
          {
            email: this.dataObj.email
          }, {
            params: {
              start: this.dataObj.startDate,
              end: this.dataObj.endDate
            }
          }
      )
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
