function fallbackCopyTextToClipboard (text) {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
    // var successful = document.execCommand('copy')
    // var msg = successful ? 'successful' : 'unsuccessful'
    // console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    // console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

function formatDateObject (dateObj) {
  const offset = dateObj.getTimezoneOffset()
  dateObj = new Date(dateObj.getTime() - (offset * 60 * 1000))
  return dateObj.toISOString().replace('T', ', ').replace('Z', '')
}

export default {
  methods: {
    getDateObj (date) {
      switch (typeof date) {
        case 'string':
          return new Date(date)
        case 'number':
          return new Date(date * 1000)
        case 'object': // array is an object...
          // date example = [ 2025, 3, 3 ]
          if (date.length !== 3) {
            this.logEvent('error', {
              message: 'stringUtils.getDateObj() - Invalid date array',
              inputDate: date,
            })
            return new Date(date)
          }

          // Extract the year, month, and day from the array
          const [ year, month, day ] = date;

          // Format the month and day to ensure they are two digits
          const formattedMonth = String(month).padStart(2, '0');
          const formattedDay = String(day).padStart(2, '0');

          // Combine them into the desired string format
          const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

          return new Date(formattedDate)
        default:
          return null
      }
    },
    formatDuration (durationMicroseconds) {
      const ms = Math.floor(durationMicroseconds / 1000)
      const s = Math.floor(ms / 1000)
      const m = Math.floor(s / 60)
      const h = Math.floor(m / 60)
      const d = Math.floor(h / 24)

      const sStr = s ? s % 60 + 's ' : ''
      const mStr = m ? m % 60 + 'm ' : ''
      const hStr = h ? h % 24 + 'h ' : ''
      const dStr = d ? d + 'd ' : ''

      return `${dStr}${hStr}${mStr}${sStr}${ms % 1000}ms`

      // return `${d}d ${h % 24}h ${m % 60}m ${s % 60}s ${ms % 1000}ms`
      // return `${d}d-${h % 24}h-${m % 60}m-${s % 60}s-${ms % 1000}ms`
    },
    formatDateTime (dateNumber) {
      return dateNumber
          ? formatDateObject(new Date(dateNumber * 1000))
          // ? new Date(dateNumber * 1000).toLocaleString(this.$store.getters.language)
          : this.$t('No update in selected period')
    },
    formatDateNoSecs (dateNumber) {
      return dateNumber
          ? formatDateObject(new Date(dateNumber * 1000)).substring(0, 17)
          // ? new Date(dateNumber * 1000).toLocaleString(this.$store.getters.language)
          : this.$t('No update in selected period')
    },
    formatDateLocale (inputDate, defaultVal = '-') {
      if (!inputDate) return defaultVal
      const date = this.getDateObj(inputDate)
      const dateStr = date.toLocaleString(this.$store.getters.language).split(' ')[ 0 ]

      if (dateStr.endsWith(',')) {
        return dateStr.slice(0, -1)
      } else {
        return dateStr
      }
    },
    formatDateYearFirst (dateNumber) {
      return new Date(dateNumber).toISOString().slice(0, 10)
    },
    formatMoney (value, currency = 'eur') {
      const currencySuffixes = {
        eur: '€',
        sar: '﷼',
        usd: '$',
        gbp: '£',
      }
      let suffix = currencySuffixes[ currency ]

      if (suffix === undefined) {
        console.warn('Unknown currency requested for display:', currency, "Defaulting to its name: ", currency)
        suffix = currency
      }

      return (value / 100).toFixed(2) + ' ' + suffix
    },

    copyTextToClipboard (text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text)
      // .then(function () {
      //   console.log('Async: Copying to clipboard was successful!')
      // }, function (err) {
      //   console.error('Async: Could not copy text: ', err)
      // })
    },

    createFileAndDownload (data, type, filename) {
      const blob = new Blob([ data ], { type })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url

      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    }
  }
}
