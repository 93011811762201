<script>
import AmountProgressCircular from "@/components/UIAccessories/AmountProgressCircular.vue";
import ExcludeFromStatsDialog from "@/components/ExcludeFromStatsDialog.vue";
import FtFromToSelect from "@/components/UIAccessories/FtFromToSelect.vue";
import {mapGetters} from "vuex";
import FtCardLayout from "@/components/UIAccessories/FtCardLayout.vue";

export default {
  name: 'Dashboard',
  components: { FtCardLayout, FtFromToSelect, ExcludeFromStatsDialog, AmountProgressCircular },
  data () {
    return {
      amounts: {
        purchases: 0,
        purchasesCount: 0,
        legacy: 0,
        cashTopUps: 0,
        cardTopUps: 0,
        dashboardCardTopUps: 0,
        dashboardCashTopUps: 0,
        dashboardGiftTopUps: 0
      },
      userUsages: []
    };
  },
  computed: {
    ...mapGetters([ 'isAdminLoggedAsClient', 'isGolfClient' ]),
    headers () {
      return [
        { text: this.$t('Email'), value: 'email' },
        { text: this.$t('Name'), value: 'name' },
        { text: this.$t('Last name'), value: 'surname' },
        { text: this.$t('Last activity'), value: 'lastActivity' },
        { text: this.$t('Purchases count'), value: 'purchasesCount' },
        { text: this.$t('Purchases'), value: 'purchases' },
        { text: this.$t('Paid entrances'), value: 'entranceFees' },
      ]
    },
    totalTopUps () {
      const dashboard = this.amounts.dashboardCardTopUps + this.amounts.dashboardCashTopUps + this.amounts.dashboardGiftTopUps
      return this.amounts.cardTopUps + this.amounts.cashTopUps + dashboard;
    },
    totalPurchases () {
      return this.amounts.purchases + this.amounts.legacy;
    },
  },
  methods: {
    async fetchData ({ from, to }) {
      try {
        const params = { from, to }

        await this.getDashboardData(params)

        if (this.isGolfClient) {
          await this.getUsagesData(params)
        }
      } catch (e) {
      }
    },
    async getDashboardData (params) {
      try {
        const res = await this.$http.reports.get('v2/dashboard', {
          params
        })
        Object.assign(this.amounts, res.data);
        // this.amounts = res.data;
      } catch (e) {
      }
    },
    async getUsagesData (params) {
      try {
        const res = await this.$http.payment.get('usages/users', {
          params
        })
        this.userUsages = res.data;
      } catch (e) {
      }
    },
    openTransactions (row) {
      this.$router.push(`/users-wallet/${this.$store.getters.clientId}/transactions/${row.userId}`)
    },
  },
};
</script>

<template>
  <v-container>
    <h1>Dashboard</h1>

    <!-- Period Selector -->
    <ft-from-to-select :fetch-data="fetchData" default-today>
      <v-spacer/>

      <exclude-from-stats-dialog v-if="isAdminLoggedAsClient"/>
    </ft-from-to-select>

    <div v-if="amounts">

      <!-- Purchases -->
      <ft-card-layout :title="$t('Purchases total:') + ' ' + formatMoney(totalPurchases)">
        <v-row class="my-1">
          <v-col cols="12" sm="6">
            <amount-progress-circular
                :text="$t('With Apollo app').toString()"
                :count="amounts.purchasesCount"
                color="primary"
                :amount="amounts.purchases"
                :total="totalPurchases"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <amount-progress-circular
                :text="$t('Without Apollo app').toString()"
                color="secondary"
                :amount="amounts.legacy"
                :total="totalPurchases"
            />
          </v-col>
        </v-row>
      </ft-card-layout>

      <!-- Top ups -->
      <ft-card-layout :title="$t('Apollo top ups total:') + ' ' + formatMoney(totalTopUps)">
        <v-row class="my-1">
          <v-col cols="12" sm="6" :md="isGolfClient ? '3' : '4'">
            <amount-progress-circular
                :text="$t('Credit card in app').toString()"
                color="primary"
                :amount="amounts.cardTopUps"
                :total="totalTopUps"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" v-if="isGolfClient">
            <amount-progress-circular
                :text="$t('Credit card at front desk').toString()"
                color="lighter"
                :amount="amounts.dashboardCardTopUps"
                :total="totalTopUps"
            />
          </v-col>
          <v-col cols="12" sm="6" :md="isGolfClient ? '3' : '4'">
            <amount-progress-circular
                :text="$t('Cash').toString()"
                color="secondary"
                :amount="amounts.cashTopUps + amounts.dashboardCashTopUps"
                :total="totalTopUps"
            />
          </v-col>
          <v-col cols="12" :sm="isGolfClient ? '6' : '12'" :md="isGolfClient ? '3' : '4'">
            <amount-progress-circular
                :text="$t('Gift').toString()"
                color="success"
                :amount="amounts.dashboardGiftTopUps"
                :total="totalTopUps"
            />
          </v-col>
        </v-row>
      </ft-card-layout>

      <ft-card-layout :title="$t('Attendances in driving range')" no-text-content v-if="isGolfClient">
        <v-data-table
            :items="userUsages"
            :headers="headers"
            item-key="user.id"
            sort-by="lastActivity"
            sort-desc
        >
          <!-- backend return values sorted by last activity -->
          <template v-slot:item.email="{ item, value }">
            <v-chip outlined @click="openTransactions(item)">
              {{ value }}
            </v-chip>
          </template>

          <template v-slot:item.lastActivity="{ value }">
            {{ formatDateNoSecs(value) }}
          </template>
          <template v-slot:item.purchases="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:item.entranceFees="{ value }">
            {{ value ? formatMoney(value) : '-' }}
          </template>
        </v-data-table>
      </ft-card-layout>

    </div>
  </v-container>
</template>
