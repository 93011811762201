<template>
  <div v-if="group">
    <h1>
      {{ $t('Group') }} "{{ group.name }}"
    </h1>

    <v-card outlined class="my-2">
      <v-card-title>
        {{ $t('Users in the group') }}
      </v-card-title>
      <v-card-text>
        <div class="d-flex align-center mt-1 mx-5">
          <v-text-field
              v-model="search"
              append-icon="fa-search"
              outlined
              :label="$t('Search')"
              class="mr-6"
              single-line
              hide-details/>
          <v-btn
              color="success"
              @click="addUser"
          >
            {{ $t('Add user') }}
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
          :items="group.users"
          :headers="headers"
          :search="search"
          :item-class="getClassForItem"
          item-key="item.id"
          :no-data-text="$t('No user yet. Add the first now!')"
      >
        <template v-slot:item.startDate="{ value }">
          {{ formatDateLocale(value) }}
        </template>
        <template v-slot:item.endDate="{ value }">
          {{ formatDateLocale(value) }}
        </template>
        <template v-slot:item.crud="{ item }">
          <v-btn color="warning"
                 class="mx-1"
                 small icon
                 @click="editUser(item)">
            <v-icon small>fa-edit</v-icon>
          </v-btn>

          <v-btn color="red"
                 class="mx-1"
                 small icon
                 @click="removeUser(item)">
            <v-icon small>fa-user-minus</v-icon>
          </v-btn>

          <!-- Add info icon that shows info about the row -->
          <v-tooltip left v-if="!item.currentlyInGroup">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mx-1"
                  small icon
                  color="info"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>fa-circle-info</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('This user is not currently in the group since it is not in the validity period.') }}
              <br>
              {{ $t('If you want this user to be in the group, change its validity dates.') }}
            </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="my-6" outlined v-if="group.usersNotReg.length">
      <v-card-title>
        {{ $t('Users added but not yet registered on Apollo') }}
      </v-card-title>
      <v-data-table
          :items="group.usersNotReg"
          :headers="uNotRegHeaders"
          :search="search"
          item-key="item.id"
          :no-data-text="$t('No user yet. Add the first now!')"
      >
        <template v-slot:item.startDate="{ value }">
          {{ formatDateLocale(value, '-') }}
        </template>
        <template v-slot:item.endDate="{ value }">
          {{ formatDateLocale(value, '-') }}
        </template>
        <template v-slot:item.crud="{ item }">
          <v-btn color="red"
                 class="pa-5"
                 small icon
                 @click="removeUserNotReg(item)">
            <v-icon small>fa-user-minus</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <add-user-to-group-modal
        v-model="userInGroupModal.visible"
        :data-obj.sync="userInGroupModal.obj"
        :edit-mode="userInGroupModal.editMode"
        :group-id="groupId"
        @close="reload"
    />
  </div>
</template>

<script>
import AddUserToGroupModal from "@/components/modals/business/AddUserToGroupModal.vue";

export default {
  name: 'UserGroupDetails',
  components: { AddUserToGroupModal },
  data () {
    return {
      group: null,
      search: '',

      userInGroupModal: {
        visible: false,
        obj: {},
        editMode: true,
      }
    }
  },
  computed: {
    groupId () {
      return this.$route.params.groupId
    },
    tokenHeaders () {
      return [
        { text: 'Email', value: 'email' },
        { text: '', value: 'crud' }
      ]
    },
    uNotRegHeaders () {
      return [
        { text: 'Email', value: 'email' },
        { text: this.$t('Beginning of validity'), value: 'startDate', },
        { text: this.$t('End of validity'), value: 'endDate', },
        { text: '', value: 'crud' }
      ]
    },
    headers () {
      return [
        { text: 'Email', value: 'email' },
        { text: this.$t('Name'), value: 'name' },
        { text: this.$t('Last name'), value: 'surname' },
        { text: this.$t('Beginning of validity'), value: 'startDate', },
        { text: this.$t('End of validity'), value: 'endDate', },
        { text: '', value: 'crud' }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getGroupInfo()
    },
    reload () {
      setTimeout(this.init, 600)
    },
    getClassForItem (item) {
      return item.currentlyInGroup ? '' : 'yellow lighten-5'
    },
    async getGroupInfo () {
      try {
        const response = await this.$http.auth.get('/user-groups/' + this.groupId)

        // needed this trick to force the interface to reload data
        this.group = response.data
        // this.group = null
        // this.$nextTick(() => {
        //   this.group = response.data
        // })
      } catch (e) {
      }
    },

    async removeUser (item) {
      try {
        const res = await this.$dialog.confirm({
          title: 'Rimuovi utente',
          text: 'Sei sicuro di voler rimuovere l\'utente dal gruppo?',
        })
        if (!res) return
        await this.$http.auth.delete('/user-groups/' + this.group.id + '/users/' + item.id)
        this.reload()
      } catch (e) {
      }
    },
    async removeUserNotReg (item) {
      try {
        const res = await this.$dialog.confirm({
          title: 'Rimuovi utente',
          text: 'Sei sicuro di voler rimuovere l\'utente dal gruppo?',
        })
        if (!res) return
        await this.$http.auth.delete('/user-groups/' + this.group.id + '/users-not-reg/' + item.id)
        this.reload()
      } catch (e) {
      }
    },
    addUser () {
      this.userInGroupModal.editMode = false
      this.userInGroupModal.obj = {}
      this.userInGroupModal.visible = true
    },
    editUser (item) {
      this.userInGroupModal.editMode = true
      this.userInGroupModal.obj = {
        userId: item.id,
        startDate: item.startDate,
        endDate: item.endDate,
      }
      this.userInGroupModal.visible = true
    },
  }
}
</script>
