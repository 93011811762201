var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.group)?_c('div',[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('Group'))+" \""+_vm._s(_vm.group.name)+"\" ")]),_c('v-card',{staticClass:"my-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Users in the group'))+" ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex align-center mt-1 mx-5"},[_c('v-text-field',{staticClass:"mr-6",attrs:{"append-icon":"fa-search","outlined":"","label":_vm.$t('Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.addUser}},[_vm._v(" "+_vm._s(_vm.$t('Add user'))+" ")])],1)]),_c('v-data-table',{attrs:{"items":_vm.group.users,"headers":_vm.headers,"search":_vm.search,"item-class":_vm.getClassForItem,"item-key":"item.id","no-data-text":_vm.$t('No user yet. Add the first now!')},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateLocale(value))+" ")]}},{key:"item.endDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateLocale(value))+" ")]}},{key:"item.crud",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"color":"warning","small":"","icon":""},on:{"click":function($event){return _vm.editUser(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-edit")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"red","small":"","icon":""},on:{"click":function($event){return _vm.removeUser(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-user-minus")])],1),(!item.currentlyInGroup)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"small":"","icon":"","color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-circle-info")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('This user is not currently in the group since it is not in the validity period.'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('If you want this user to be in the group, change its validity dates.'))+" ")])]):_vm._e()]}}],null,false,1349825667)})],1),(_vm.group.usersNotReg.length)?_c('v-card',{staticClass:"my-6",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Users added but not yet registered on Apollo'))+" ")]),_c('v-data-table',{attrs:{"items":_vm.group.usersNotReg,"headers":_vm.uNotRegHeaders,"search":_vm.search,"item-key":"item.id","no-data-text":_vm.$t('No user yet. Add the first now!')},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateLocale(value, '-'))+" ")]}},{key:"item.endDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateLocale(value, '-'))+" ")]}},{key:"item.crud",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-5",attrs:{"color":"red","small":"","icon":""},on:{"click":function($event){return _vm.removeUserNotReg(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-user-minus")])],1)]}}],null,false,566280394)})],1):_vm._e(),_c('add-user-to-group-modal',{attrs:{"data-obj":_vm.userInGroupModal.obj,"edit-mode":_vm.userInGroupModal.editMode,"group-id":_vm.groupId},on:{"update:dataObj":function($event){return _vm.$set(_vm.userInGroupModal, "obj", $event)},"update:data-obj":function($event){return _vm.$set(_vm.userInGroupModal, "obj", $event)},"close":_vm.reload},model:{value:(_vm.userInGroupModal.visible),callback:function ($$v) {_vm.$set(_vm.userInGroupModal, "visible", $$v)},expression:"userInGroupModal.visible"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }