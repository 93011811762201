<script>
import FtColInfoView from "@/components/UIAccessories/FtColInfoView.vue";

export default {
  name: "MonthlyReports",
  components: { FtColInfoView },
  data () {
    return {
      reports: [],
      platformFees: null,
      hideZero: false,
      itemsPerPage: -1
    }
  },
  computed: {
    headers () {
      return [
        { value: 'generatedDate', text: this.$t('Generation date') },
        { value: 'yearText', text: this.$t('Year/month') },
        { value: 'invoiceNumber', text: this.$t('Invoice n.') },
        { value: 'grossStr', text: this.$t('Gross') },
        { value: 'feesStr', text: this.$t('Apollo cost (no VAT)') },
        { value: 'feesVatStr', text: this.$t('Apollo VAT') },
        { value: 'actions', text: this.$t('Actions') },
      ]
    },
    reportsComputed () {
      return this.reports.filter(i => this.hideZero ? i.gross > 0 : true)
    }
  },
  mounted () {
    this.getMonthlyReports()
    this.getPlatformFee()
  },
  methods: {
    async getMonthlyReports () {
      try {
        const r = await this.$http.reports('v2/monthly-reports')

        r.data.forEach(i => {
          i.generatedDate = this.formatDateLocale(i.generatedDate)
          i.yearText = `${i.year}/${i.month}`

          i.grossStr = this.formatMoney(i.gross)
          i.feesStr = this.formatMoney(i.fees)
          i.feesVatStr = this.formatMoney(i.feesVat)
        })

        this.reports = r.data
      } catch (e) {
      }
    },
    async getPlatformFee () {
      try {
        const res = await this.$http.payment.get("platform-fees/" + this.$store.getters.clientId);
        const { platformFixedFee, platformPercentFee } = res.data
        this.platformFees = {
          fixed: platformFixedFee ?? 25,
          percent: platformPercentFee ?? 5
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async downloadExcel (item) {
      const res = await this.$http.reports(`v2/monthly-reports/${item.id}/transactions/download`)

      this.downloadFileFromUrl(res.data)
    }
  }
}
</script>

<template>
  <div>
    <h1>{{ $t('Monthly reports') }}</h1>

    <v-card v-if="platformFees" style="width: auto; max-width: 35rem" class="mt-3 mb-5" outlined>
      <v-card-title>{{ $t('Platform fees') }}</v-card-title>
      <v-card-text>
        <v-row>
          <ft-col-info-view full-width :label="$t('Fixed fee per transaction')">
            <v-chip class="ml-1" outlined>{{ formatMoney(platformFees.fixed) }}</v-chip>
          </ft-col-info-view>
        </v-row>
        <v-row>
          <ft-col-info-view full-width :label="$t('Percentage')">
            <v-chip class="ml-1" outlined>{{ platformFees.percent }} %</v-chip>
          </ft-col-info-view>
        </v-row>
      </v-card-text>
    </v-card>

    <v-alert
        outlined prominent
        type="info" border="left"
    >
      <b v-text="$t('For detailed instructions')"/>
      {{
        $t('on utilising these monthly reports in the statement of earnings, please refer to the comprehensive documentation available by clicking the button below.')
      }}

      <div v-if="$vuetify.breakpoint.smAndDown" class="mt-4">
        <v-btn
            color="info" outlined
            href="https://foodytek.notion.site/APOLLO-by-FoodyTek-Contabilit-incassi-ricariche-tramite-Stripe-b1a9284b333a407e97cbae5476c85216?pvs=4"
            target="_blank"
            :small="$vuetify.breakpoint.xs"
        >
          {{ $t('Go to the documentation') }}
        </v-btn>
      </div>

      <template v-slot:append>
        <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            color="info" outlined
            href="https://foodytek.notion.site/APOLLO-by-FoodyTek-Contabilit-incassi-ricariche-tramite-Stripe-b1a9284b333a407e97cbae5476c85216?pvs=4"
            target="_blank"
            class="ml-3"
        >
          {{ $t('Go to the documentation') }}
        </v-btn>
      </template>
    </v-alert>

    <v-alert
        v-if="!$store.getters.isClientAccountant"
        outlined prominent
        type="info" border="left"
    >

      <b v-text="$t('Do you know that you can invite your accountant to see these monthly reports?')"/>
      {{
        $t('Click here to invite them to sign up to the Apollo platform to let them independently view and download what they need!')
      }}

      <div v-if="$vuetify.breakpoint.smAndDown" class="mt-4">
        <v-btn
            color="info" outlined
            to="/client-users?invite=accountant"
            class="ml-3"
            :small="$vuetify.breakpoint.xs"
        >
          {{ $t('Invite accountant') }}
        </v-btn>
      </div>

      <template v-slot:append>
        <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            color="info" outlined
            to="/client-users?invite=accountant"
            class="ml-3"
        >
          {{ $t('Invite accountant') }}
        </v-btn>
      </template>
    </v-alert>

    <v-checkbox
        v-model="hideZero"
        :label="$t('Hide reports with no transactions (0 € gross)')"
    />

    <v-card outlined class="mt-3">
      <v-data-table
          hide-default-footer
          :items="reportsComputed"
          :headers="headers"
          :items-per-page="itemsPerPage"
          item-key="to"
          disable-sort
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :disabled="item.fees === 0"
                  @click="downloadExcel(item)"
              >
                <v-icon>fa-file-excel</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Download Excel file with month transactions') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
