export default {
  methods: {
    downloadFileFromUrl(url) {
      // Create a link element
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'

      // Append the link to the body and trigger the download
      document.body.appendChild(link)
      link.click()

      // Remove the link after download
      document.body.removeChild(link)
    },
    downloadFile(data, filename) {
      // Create a Blob URL
      const url = URL.createObjectURL(new Blob([data], { type: 'application/zip' }));
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;

      // Append the link, trigger download, then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the Blob URL to free memory
      URL.revokeObjectURL(url);
    },
    waitAsync (millis) {
      return new Promise((success) => {
        setTimeout(success, millis)
      })
    }
  }
}
