<script>
import InviteUserToPlatformModal from "@/components/modals/commons/InviteUserToPlatformModal.vue";

export default {
  name: 'ClientOrganisationUsersCmp',
  components: { InviteUserToPlatformModal },
  props: {
    ftClientId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      users: [],
      tokens: [],
      search: '',
      inviteModal: {
        show: false,
        defaultRole: null
      },

      tokenHeaders: [
        { text: 'Email', value: 'email' },
        { text: this.$t('Role'), value: 'role' },
        { text: this.$t('Actions'), value: 'crud', align: 'center', sortable: false }
      ]
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('Name'), value: 'name' },
        { text: this.$t('Last name'), value: 'surname' },
        { text: 'Email', value: 'email' },
        { text: this.$t('Phone number'), value: 'mobilePhoneNumber' },
        { text: this.$t('Role'), value: 'role' },
        { text: this.$t('Actions'), value: 'crud', align: 'center', sortable: false, hide: !this.$store.getters.isClientAdmin }
      ].filter(i => !i.hide)
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      if (this.$store.getters.isClientAdmin) {
        this.getRegistrationTokens()
      }
      this.getUsers()

      if (this.$store.getters.isClientAdmin && this.$route.query.invite) {
        this.openInviteModal(this.$route.query.invite)
        this.$router.replace({ query: {} })
      }
    },
    reload () {
      setTimeout(this.init, 200)
    },
    openInviteModal (roleQuery) {
      if (roleQuery) {
        this.inviteModal.defaultRole = 'foodytek-client-' + roleQuery
      }
      this.inviteModal.show = true
    },
    getRoleFromCode (roleCode) {
      switch (roleCode) {
        case 'foodytek-client-admin':
          return { label: this.$t('Administrator'), color: 'warning darken-2' }
        case 'foodytek-client-installer':
          return { label: this.$t('Installer'), color: 'grey darken-1' }
        case 'foodytek-client-accountant':
          return { label: this.$t('Accountant'), color: 'primary' }
        case 'foodytek-client-front-desk':
          return { label: this.$t('Front desk'), color: 'secondary' }
        default:
          return { label: '-', color: 'danger darken-2' }
      }
    },
    async getUsers () {
      try {
        const response = await this.$http.auth.get('/users/ft-client/' + this.ftClientId)
        response.data.forEach(i => {
          i.role = this.getRoleFromCode(i.roleCode)
        })
        this.users = response.data
      } catch (e) {
      }
    },
    async getRegistrationTokens () {
      try {
        const response = await this.$http.auth.get('invitations/ft-client')
        response.data.forEach(i => {
          i.role = this.getRoleFromCode(i.roleCode)
        })
        this.tokens = response.data
      } catch (e) {
      }
    },

    async removeUser (item) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('Do you really want to downgrade the user <b>{email}</b>?', { email: item.email }), this.$t('Downgrade'))
        await this.$http.auth.delete(`users/${item.id}/downgrade`)
        this.reload()
      } catch (e) {
      }
    },
    async revokeToken (item) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('Do you really want to revoke the registration token sent to the email <b>{email}</b>?', { email: item.email }), this.$t('Revoke'))
        await this.$http.auth.delete('invitations/' + item.id)
        this.reload()
      } catch (e) {
      }
    }
  }
}
</script>

<template>
  <div>
    <v-card class="my-6" outlined v-if="tokens && tokens.length">
      <v-card-title>{{ $t('Active registration links') }}</v-card-title>
      <v-card-text>
        <v-data-table
            :items="tokens"
            :headers="tokenHeaders"
            item-key="item.id"
            :no-data-text="$t(`There's no user yet. Add the first one!`)"
        >
          <template v-slot:item.role="{ value }">
            <v-chip outlined :color="value.color" small>{{ value.label }}</v-chip>
          </template>

          <template v-slot:item.crud="{ item }">
            <v-btn color="red"
                   class="pa-5"
                   small icon
                   @click="revokeToken(item)">
              <v-icon small>fa-trash</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card class="my-6" outlined>
      <v-card-title>Utenti che hanno accesso alla dashboard</v-card-title>
      <v-card-text>
        <div class="d-flex align-center mx-5">
          <v-text-field
              v-model="search"
              append-icon="fa-search"
              outlined
              :label="$t('Search')"
              single-line
              hide-details/>
          <v-btn
              v-if="$store.getters.isClientAdmin"
              class="ml-6"
              color="green" dark
              v-text="'Invita'"
              @click="openInviteModal()"/>
        </div>
      </v-card-text>
        <v-data-table
            :items="users"
            :headers="headers"
            :search="search"
            item-key="item.id"
            :no-data-text="$t(`There's no user yet. Add the first one!`)"
        >
          <template v-slot:item.role="{ value }">
            <v-chip outlined :color="value.color" small>{{ value.label }}</v-chip>
          </template>

          <template v-slot:item.crud="{ item }">
            <v-btn color="red"
                   class="pa-5"
                   small icon
                   @click="removeUser(item)">
              <v-icon small>fa-angle-double-down</v-icon>
            </v-btn>
          </template>
        </v-data-table>
    </v-card>

    <invite-user-to-platform-modal
        v-if="$store.getters.isClientAdmin"
        v-model="inviteModal.show"
        :default-role="inviteModal.defaultRole"
        @close="reload"
    />
  </div>
</template>
