<script>
import FtFromToSelect from "@/components/UIAccessories/FtFromToSelect.vue";

export default {
  name: "StatsPerUser",
  components: { FtFromToSelect },
  data () {
    return {
      items: [],
    }
  },
  computed: {
    headers() {
      return [
        { value: 'email', text: 'Email' },
        { value: 'name', text: this.$t('Name') },
        { value: 'surname', text: this.$t('Last name') },
        { value: 'purchases', text: this.$t('Apollo purchases') },
        { value: 'dashboardCardTopUp', text: this.$t('Front desk card top ups') },
        { value: 'inAppCardTopUp', text: this.$t('In-app card top ups') },
        { value: 'cashTopUp', text: this.$t('Cash top ups') },
        { value: 'giftTopUp', text: this.$t('Gift top ups') },
      ]
    }
  },
  methods: {
    async loadData ({ from, to }) {
      try {
        const r = await this.$http.reports('v2/dashboard/stats-per-user', {
          params: {
            from, to
          }
        })

        this.items = r.data
      } catch (e) {
      }
    },
    openTransactions (row) {
      this.$router.push(`/users-wallet/${this.$store.getters.clientId}/transactions/${row.userId}`)
    },
  }
}
</script>

<template>
  <div>
    <h1 class="mb-3">{{ $t('Per user statistics') }}</h1>

    <ft-from-to-select :fetch-data="loadData" />

    <v-card outlined>
      <v-data-table
          :items="items"
          :headers="headers"
          item-key="to"
      >
        <template v-slot:item.serialNumber="{ value }">
          <v-chip link outlined :to="`/devices/${value}`">{{ value }}</v-chip>
        </template>

        <template v-slot:item.email="{ item, value }">
          <v-chip outlined @click="openTransactions(item)">
            {{ value }}
          </v-chip>
        </template>

        <template v-slot:item.purchases="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:item.cashTopUp="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:item.dashboardCardTopUp="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:item.inAppCardTopUp="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:item.giftTopUp="{ value }">
          {{ formatMoney(value) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
