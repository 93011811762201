<script>
export default {
  name: 'FtDateField',
  props: {
    value: {
      type: String,
      required: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    label: String,
    min: String,
    max: String,
    message: String,
    errorMessages: Array
  },
  data () {
    return {
      dateMenu: false
    }
  },
  computed: {
    input: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    clear () {
      this.input = null
    }
  }
}
</script>

<template>
  <div>
    <v-menu
        ref="dateMenu"
        v-model="dateMenu"
        transition="scroll-y-transition"
        offset-y
        :close-on-content-click="false"
        solo
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            :label="label"
            :value="formatDateLocale(input, '')"
            prepend-inner-icon="fa-calendar"
            v-bind="attrs"
            v-on="on"
            readonly
            :outlined="outlined"
            :dense="dense"
            :hide-details="hideDetails"
            :error="error"
            :messages="message"
            :error-messages="errorMessages"
            :clearable="clearable"
            @click:clear.prevent="clear"
        />
      </template>
      <v-date-picker
          v-model="input"
          no-title
          :min="min"
          :max="max"
          :first-day-of-week="1"
          :locale="$store.getters.language"
          @input="dateMenu = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
