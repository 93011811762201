<template>
  <div>
    <!-- Period Selector -->
    <div class="mt-3 body-1">{{ $t('You can view the data for the desired period below.') }}</div>
    <br>
    <div class="d-inline-flex flex-wrap" style="width: 100%">
      <v-btn-toggle v-model="selectedType" mandatory class="mb-2 mx-2">
        <v-btn text disabled style="color: rgb(0,0,0)!important;">
          {{ $t('Select by') }}
        </v-btn>
        <v-btn v-for="item in periodTypes" :key="item.value" :value="item.value">
          {{ item.text }}
        </v-btn>
      </v-btn-toggle>

      <v-btn @click="decrementDate" icon>
        <v-icon>fa-chevron-left</v-icon>
      </v-btn>

      <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="dateTextField"
              :loading="loading"
              :label="$t('Select period')"
              prepend-icon="fa-calendar"
              style="min-width: 16rem; max-width: 25rem"
              class="mx-2"
              readonly
              v-bind="attrs"
              v-on="on"
          />
        </template>
        <v-date-picker
            v-if="selectedType === 'day'"
            v-model="date"
            @input="menu = false"
        />
        <v-date-picker
            v-else-if="selectedType === 'period'"
            @change="menu = false"
            v-model="date"
            range
            :max="today"
        />
        <v-date-picker
            v-else
            v-model="date"
            @input="menu = false"
            type="month"
            :picker-date.sync="date"
        />
      </v-menu>

      <v-btn @click="incrementDate" icon>
        <v-icon>fa-chevron-right</v-icon>
      </v-btn>

      <slot name="default"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FtFromToSelect',
  props: {
    fetchData: {
      type: Function, // this function must return a promise!
      required: true
    },
    defaultToday: Boolean
  },
  data () {
    const today = new Date().toISOString().slice(0, 10)

    return {
      today: today,
      monthMenu: false,
      date: null,
      selectedType: this.defaultToday ? 'day' : 'month',
      query: {},
      menu: false,
      loading: true,
    }
  },
  computed: {
    periodTypes () {
      return [
        { text: this.$t('Period'), value: 'period' },
        { text: this.$t('Month'), value: 'month' },
        { text: this.$t('Day'), value: 'day' },
      ]
    },
    dateTextField () {
      if (!this.date) return ''
      if (this.selectedType === 'period') {
        return this.date[ 0 ] + ' → ' + (this.date[ 1 ] ?? '...')
      }
      return this.date
    }
  },
  watch: {
    date (val) {
      if (!val) return
      if (this.selectedType === 'period') {
        if (val.length !== 2) return

        const from = val[ 0 ]
        const to = val[ 1 ]
        if (new Date(from) > new Date(to)) {
          this.date = [ to, from ]
          return
        }
      }

      switch (this.selectedType) {
        case 'day': {
          const from = new Date(val).toISOString()
          const toDate = new Date(val)
          toDate.setDate(toDate.getDate() + 1)
          const to = toDate.toISOString()

          this.reloadData({ from, to })
          break
        }
        case 'period': {
          const from = new Date(val[ 0 ]).toISOString()
          // set beginning of next day as iso date
          const to = new Date(new Date(val[ 1 ]).setDate(new Date(val[ 1 ]).getDate() + 1)).toISOString()

          this.reloadData({ from, to })
          break
        }
        case 'month': {
          const firstOfMonth = new Date(val + '-01')
          const from = firstOfMonth.toISOString()
          // beginning of next month
          firstOfMonth.setMonth(firstOfMonth.getMonth() + 1)
          const to = firstOfMonth.toISOString()

          this.reloadData({ from, to })
          break
        }
      }
    },
    selectedType (val) {
      let newDate
      if (this.date instanceof Array) {
        newDate = this.date[ 0 ]
      } else {
        newDate = this.date
      }

      switch (val) {
        case 'day': {
          // it should be today if newDate is in current month, the first day of month otherwise
          const today = new Date();
          const selectedMonth = new Date(newDate);
          if (selectedMonth.getMonth() === today.getMonth() && selectedMonth.getFullYear() === today.getFullYear()) {
            this.date = today.toISOString().slice(0, 10);
          } else {
            selectedMonth.setDate(1);
            this.date = selectedMonth.toISOString().slice(0, 10);
          }
          break;
        }
        case 'period': {
          const firstOfMonth = new Date(newDate);
          firstOfMonth.setDate(1);
          const to = new Date(newDate);
          to.setDate(1);
          to.setMonth(to.getMonth() + 1);
          this.date = [ firstOfMonth.toISOString().slice(0, 10), to.toISOString().slice(0, 10) ];
          break;
        }
        case 'month': {
          this.date = newDate.slice(0, 7);
          break;
        }
      }
    }
  },
  mounted () {
    if (this.defaultToday) {
      this.date = this.today
    } else {
      // Set current month as default
      this.date = new Date().toISOString().slice(0, 7)
    }
  },
  methods: {
    reloadData (fromTo) {
      this.loading = true
      const promise = this.fetchData(fromTo)

      if (!promise instanceof Promise) {
        if (this.$env.isLocalhost) {
          console.error('In component FtFromToSelect, the fetchData prop must return a promise!')
        }
        return
      }

      promise.finally(() => {
        this.loading = false
      })
    },
    decrementDate () {
      if (this.selectedType === 'day') {
        const date = new Date(this.date)
        date.setDate(date.getDate() - 1)
        this.date = date.toISOString().slice(0, 10)
      } else if (this.selectedType === 'period') {
        const from = new Date(this.date[ 0 ])
        from.setDate(from.getDate() - 1)
        const to = new Date(this.date[ 1 ])
        to.setDate(to.getDate() - 1)
        this.date = [ from.toISOString().slice(0, 10), to.toISOString().slice(0, 10) ]
      } else {
        const date = new Date(this.date)
        date.setMonth(date.getMonth() - 1)
        this.date = date.toISOString().slice(0, 7)
      }
    },
    incrementDate () {
      if (this.selectedType === 'day') {
        const date = new Date(this.date)
        date.setDate(date.getDate() + 1)
        this.date = date.toISOString().slice(0, 10)
      } else if (this.selectedType === 'period') {
        const from = new Date(this.date[ 0 ])
        from.setDate(from.getDate() + 1)
        const to = new Date(this.date[ 1 ])
        to.setDate(to.getDate() + 1)
        this.date = [ from.toISOString().slice(0, 10), to.toISOString().slice(0, 10) ]
      } else {
        const date = new Date(this.date)
        date.setDate(2) // otherwise from March it would be 2022-03-31 at 23:00
        date.setMonth(date.getMonth() + 1)
        this.date = date.toISOString().slice(0, 7)
      }
    }
  },
}
</script>

<style scoped>

</style>
