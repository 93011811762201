<template>
  <div class="ma-6">
<!--    <v-row class="justify-center mt-2 mb-4">-->
<!--      <span class="display-3">-->
<!--        Device {{ serialNumber }}-->
<!--      </span>-->
<!--    </v-row>-->

    <v-card>
      <v-card-title>
        <span class="display-1">
        Device {{ serialNumber }}
      </span>
      </v-card-title>
      <v-tabs
          v-model="tab"
          @change="onTabChange"
          background-color="accent-4"
          center-active
          align-with-title
          grow
      >
        <v-tab>Statistics</v-tab>
        <v-tab>Information</v-tab>
        <v-tab>Discounts</v-tab>
        <v-tab>Export data</v-tab>
        <v-tab v-if="isAdmin || isAdminLoggedAsClient">Monitoring</v-tab>
        <v-tab v-if="isClientAdmin">Funzioni gruppi utenti</v-tab>
      </v-tabs>

      <v-card-text>
        <v-tabs-items v-model="tab" :touchless="tab === 3 && (isAdmin || isAdminLoggedAsClient)"> <!-- Disable touch for Monitoring tab -->
          <v-tab-item>
            <device-stats-tab :serial-number="serialNumber"/>
          </v-tab-item>
          <v-tab-item>
            <information :serial-number="serialNumber"/>
          </v-tab-item>
          <v-tab-item>
            <discounts :serial-number="serialNumber"/>
          </v-tab-item>
          <v-tab-item>
            <eva-dts :serial-number="serialNumber"/>
          </v-tab-item>
          <v-tab-item v-if="isAdmin || isAdminLoggedAsClient">
            <monitoring :serial-number="serialNumber"/>
          </v-tab-item>
          <v-tab-item v-if="isClientAdmin">
            <user-group-features :serial-number="serialNumber"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import Discounts from '@/views/commons/DeviceDetails/tabs/Discounts'
import EvaDts from '@/views/commons/DeviceDetails/tabs/EvaDts'
import Information from '@/views/commons/DeviceDetails/tabs/DeviceInformationTab.vue'
import { mapGetters } from 'vuex'
import Monitoring from "@/views/commons/DeviceDetails/tabs/monitoring/_Monitoring.vue";
import UserGroupFeatures from "@/views/commons/DeviceDetails/tabs/UserGroupFeatures.vue";
import DeviceStatsTab from "@/views/commons/DeviceDetails/tabs/DeviceStatsTab.vue";

export default {
  name: 'DeviceDetails',
  components: { DeviceStatsTab, UserGroupFeatures, Monitoring, Information, EvaDts, Discounts },
  data () {
    return {
      serialNumber: this.$route.params.serialNumber,
      tab: Number(this.$route.query.tab)
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isClientAdmin', "isAdminLoggedAsClient"])
  },
  mounted () {
    this.onTabChange()
  },
  methods: {
    onTabChange () {
      this.$router.replace({ query: { tab: this.tab } })
    }
  }
}
</script>

<style scoped>

</style>
