<script>
import {mapGetters} from "vuex";
import ConnectStripeBtn from "@/components/ConnectStripeBtn.vue";

export default {
  name: 'NotificationsCmp',
  components: { ConnectStripeBtn },
  data () {
    return {
      clientSepa: {
        show: true, // only if registration is required though
        loading: false,
        setupComplete: this.$route.query.clientSepaSetupComplete === 'true',
        persistent: true
      }
    }
  },
  computed: {
    ...mapGetters([
        'stripeEnabled', 'onboardingComplete', 'isAcceptingPayments', 'requireCustomerRegistration',
        'hasUnreadFeatureNotifications'
    ]),
    routeIsNotifications () {
      return this.$route.path === '/notifications'
    }
  },
  mounted () {
    if (!this.$store.getters.isClientAdmin) return

    this.load()
  },
  methods: {
    async featureNotificationsClick () {
      await this.logEvent(
          'feature-notification-page-opened',
          {
            from: 'banner',
          }
      )
      await this.$router.push({ path: '/notifications' })
    },
    async load () {
      await this.$store.dispatch('updateClientStatus')
      await this.loadSepa()
    },
    async loadSepa () {
      if (this.clientSepa.setupComplete) {
        if (this.requireCustomerRegistration) {
          await this.waitAsync(1000)
          await this.$store.dispatch('updateClientStatus')

          if (this.requireCustomerRegistration) {
            // if event from Stripe not yet received, wait a bit more time
            await this.waitAsync(5000)
            await this.$store.dispatch('updateClientStatus')
            if (this.requireCustomerRegistration) {
              this.clientSepa.setupComplete = false
              this.$router.replace({ query: {} })
              this.$dialog.notify.warning(this.$t('Seems like the SEPA Direct Debit setup is not completed yet. Please, come back later or try again.'))
              this.clientSepa.persistent = false
              return
            }
          }
        }
        this.$router.replace({ query: {} })
        this.$dialog.notify.success(this.$t('SEPA Direct Debit setup completed successfully!'))
        return
      }

      const hasNotifications = this.$store.getters.onboardingComplete || !this.$store.getters.onboardingComplete
      if (hasNotifications) {
        // check if it gets updated after a while since notifications bother the user

        setTimeout(() => {
          this.$store.dispatch('updateClientStatus')
        }, 2000)

        setTimeout(() => {
          this.$store.dispatch('updateClientStatus')
        }, 10000)
      }
    },
    async openStripeCheckout () {
      try {
        this.clientSepa.loading = true
        const res = await this.$http.payment.post('/ft-clients/stripe-payment-methods', {
          successUrl: location.href + '?clientSepaSetupComplete=true',
          cancelUrl: location.href,
          embedded: false // change this to integrate Stripe embedded
        })

        window.location = res.data;
      } catch (e) {
        this.clientSepa.loading = true
      }
    },
  }
}
</script>

<template>
  <div>
    <v-banner
        color="accent"
        v-if="stripeEnabled && !onboardingComplete"
        sticky
    >
      <div class="text-h6 mb-1">{{ $t('Payments configuration') }}</div>
      <span v-if="isAcceptingPayments">
        {{
          $t('Payments are now enabled! However, you must complete the onboarding filling out the latest information required by Stripe. You can do so by clicking the button below. Probably, you will need to submit an identification document to verify your identity. For more information, write us at support@foodytek.com.')
        }}
      </span>
      <span v-else>
        {{
          $t('Configure the Stripe service to receive payments from your customers on your bank account directly. You can do so by clicking the "{0}" button.', [ 'Connect with stripe' ])
        }}
      </span>

      <template v-slot:actions>
        <connect-stripe-btn/>
      </template>
    </v-banner>

    <!-- Feature Notifications -->

    <v-banner
        color="lighter lighten-4"
        :class="{ 'mt-2': stripeEnabled && !onboardingComplete }"
        sticky
        v-if="hasUnreadFeatureNotifications && !routeIsNotifications"
    >
      <div class="text-h6 mb-1">{{ $t('New features are available!') }}</div>

      <div>
        {{
          $t("Hey! We've rolled out some interesting new features. Ready to explore? Click below to check them out!")
        }}
      </div>

      <template v-slot:actions>
        <v-btn color="primary" text @click="featureNotificationsClick">{{ $t('Check it out') }}</v-btn>
      </template>
    </v-banner>

    <!-- Dialog component -->
    <v-dialog :value="requireCustomerRegistration && clientSepa.show" :persistent="clientSepa.persistent"
              max-width="500px">
      <v-card :loading="clientSepa.setupComplete">
        <v-card-title class="headline">{{ $t('Configure SEPA Direct Debit') }}</v-card-title>
        <v-card-text>
          <p>
            {{
              $t('Add your IBAN to enable SEPA Direct Debit, ensuring charges only for amounts agreed with Apollo by FoodyTek.')
            }}
          </p>
          <p>
            {{ $t('You’ll receive an email notification on the payment date.') }}
          </p>
          <p>
            {{
              $t('If a payment fails due to insufficient funds, incorrect details, or a bank block, a €15 penalty will be applied to cover the associated costs.')
            }}
          </p>
          <p>
            {{ $t('For complete details,') }}
            <a href="https://foodytek.notion.site/Addebiti-automatici-SEPA-Direct-Debt-SDD-15abea75a37180ad83bde6f6ab9081bb"
               target="_blank">
              {{ $t('click here') }}</a>.
            {{ $t('Save the link for future reference.') }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!clientSepa.persistent" color="grey" text :loading="clientSepa.loading"
                 @click="clientSepa.show = false">
            {{ $t('Continue next time') }}
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="openStripeCheckout" :loading="clientSepa.loading">
            {{ $t('Add IBAN') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
