<script>
export default {
  name: 'FeatureNotifications',
  data () {
    return {
      featureNotifications: [],
      hideNotificationsTimeout: null
    }
  },
  computed: {
  },
  methods: {
    async loadFeatureNotifications () {
      const res = await this.$http.auth.get('feature-notifications')
      this.featureNotifications = res.data
    },
    async markNotificationAsRead (notification) {
      const featureCode = notification.featureCode
      if (notification.unread) {
        await this.$http.auth.post(`feature-notifications/${featureCode}/mark-as-read`)
        notification.unread = false
      }
      await this.logEvent(
        'feature-notification-read',
        { featureCode }
      )
      await this.$store.dispatch('updateNotificationsStatus')
    }
  },
  mounted () {
    this.loadFeatureNotifications()

    // hide notifications only if the user stays on the page for 3 seconds
    this.hideNotificationsTimeout = setTimeout(() => {
    }, 3000)
  },
  destroyed () {
    clearTimeout(this.hideNotificationsTimeout)
  }
}
</script>

<template>
  <div id="feature-notifications" class="">
    <h1 class="mb-3">{{ $t("Notifications of the latest features") }}</h1>

    <v-alert color="lighter lighten-4">
      <template v-slot:prepend>
        <div>
          <v-icon>fa-bell</v-icon>
        </div>
      </template>
      <div class="ml-4">{{ $t("Note that the new feature icon and banner will stay visible until you open the notifications, ensuring you never miss an update.") }}</div>
    </v-alert>

    <v-row>
      <v-col cols="12" md="6"
             v-for="notification in featureNotifications"
             v-bind:key="notification.featureCode"
      >
        <v-card :href="notification.notionLink" target="_blank"
                @click="markNotificationAsRead(notification)"
                outlined
        >
          <v-card-title>
            <div v-html="notification.title"/>
            <v-chip color="green"
                    text-color="white" class="ml-3"
                    v-if="notification.unread"
            >
              New!
            </v-chip>
          </v-card-title>
          <v-card-subtitle v-text="$t('Published on ') + formatDateLocale(notification.date)"/>
          <v-card-text v-html="notification.message"/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>