var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('action-dialog',{attrs:{"data":_vm.dataObj,"edit-mode":_vm.editMode,"submit-btn-text":_vm.$t('Add'),"edit-btn-text":_vm.$t('Edit'),"cancel-btn-text":_vm.$t('Cancel'),"loading-text":_vm.$t('Loading...'),"submit-title":_vm.userEmail ? _vm.$t('Add {email} to a group', { email: _vm.userEmail }) : _vm.$t('Add a new user via email'),"submit-success-message":_vm.$t('User added successfully!'),"edit-title":_vm.$t('Change user validity period'),"edit-success-message":_vm.$t('Validity period changed'),"fields":_vm.fields,"field-default-options":_vm.fieldDefaultOptions,"submit-promise-callback":_vm.create,"edit-promise-callback":_vm.edit},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"field.groupId",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [_c('v-autocomplete',{class:classes,attrs:{"label":label,"outlined":"","items":_vm.groups,"error-messages":errors},model:{value:(_vm.dataObj.groupId),callback:function ($$v) {_vm.$set(_vm.dataObj, "groupId", $$v)},expression:"dataObj.groupId"}})]}},{key:"field.startDate",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [(_vm.dataObj)?_c('ft-date-field',{class:classes,attrs:{"label":label,"outlined":"","clearable":"","message":_vm.$t('It can be empty'),"error-messages":errors},model:{value:(_vm.dataObj.startDate),callback:function ($$v) {_vm.$set(_vm.dataObj, "startDate", $$v)},expression:"dataObj.startDate"}}):_vm._e()]}},{key:"field.endDate",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [(_vm.dataObj)?_c('ft-date-field',{class:classes,attrs:{"label":label,"min":_vm.minDate,"outlined":"","clearable":"","message":_vm.$t('It can be empty'),"error-messages":errors},model:{value:(_vm.dataObj.endDate),callback:function ($$v) {_vm.$set(_vm.dataObj, "endDate", $$v)},expression:"dataObj.endDate"}}):_vm._e()]}}]),model:{value:(_vm.showValue),callback:function ($$v) {_vm.showValue=$$v},expression:"showValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }