export default {
  methods: {
    async logEvent(type, metadata) {
      try {
        await this.$http.auth.post(`logging/event`, {
          type,
          metadata
        })
      } catch (e) {
        console.log('Error logging event', e)
      }
    }
  }
}
