<script>
export default {
  name: 'DeveloperTools',
  data () {
    const lastMonth = new Date()
    lastMonth.setMonth(new Date().getMonth() - 1)
    return {
      year: lastMonth.getFullYear(),
      month: lastMonth.getMonth() + 1 // returns 0-11 -> 1-12
    }
  },
  methods: {
    async initAuth() {
      await this.$http.auth.post('data-init')
      await this.$dialog.notify.success('Auth data initialized')
    },
    async initDevices() {
      await this.$http.device.post('data-init')
      await this.$dialog.notify.success('Devices data initialized')
    },
    async initPayments() {
      await this.$http.payment.post('data-init')
      await this.$dialog.notify.success('Payments data initialized')
    },
    async initReportsV2() {
      await this.$http.reports.post('v2/data-init')
      await this.$dialog.notify.success('Reports V2 data initialized')
    },
    async downloadZipInvoices() {
      const res = await this.$http.reports.get('v2/monthly-reports/all-invoices-zip', {
        params: {
          year: this.year,
          month: this.month
        },
        responseType: 'blob'
      })

      console.log(res)
      console.log(res.data.length)

      this.downloadFile(res.data, 'invoices.zip')
      // this.downloadFileOld(res.data, 'invoices.mine.zip')
    },
  }
}
</script>

<template>
<div>
  <h1>Developer tools</h1>

  <div class="mt-3">
    <h3>Run init for microservices</h3>

    <v-btn class="mx-1" outlined @click="initAuth">Auth</v-btn>
    <v-btn class="mx-1" outlined @click="initDevices">Devices</v-btn>
    <v-btn class="mx-1" outlined @click="initPayments">Payments</v-btn>
    <v-btn class="mx-1" outlined @click="initReportsV2">Reports V2</v-btn>
  </div>

  <div class="my-6">
    <h3>Download invoices</h3>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field v-model="year" label="Year" type="number" min="2020" max="2028"/>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field v-model="month" label="Month" type="number" min="1" max="12"/>
      </v-col>
      <v-col cols="12" sm="4">
        <v-btn class="mx-1" outlined @click="downloadZipInvoices">Download</v-btn>
      </v-col>
    </v-row>
  </div>
</div>
</template>

<style scoped>

</style>